import * as React from 'react';
import { Helmet } from 'react-helmet';

import CtaSection from '../components/CtaSection';
import Features from '../components/Features';
import Prestations from '../components/Prestations';
import Zones from '../components/Zones';

import Favicon from '../images/favicon.ico';
import BlogSection from '../components/BlogSection';
import Layout from '../components/Layout';
import OurPartners from '../components/OurPartners';

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <link rel="icon" href={Favicon} />
        <meta charSet="utf-8" />
        <title>
          Serrurier d’urgence, devis rapide, pas cher et pro - dépannage 24/7
        </title>
        <meta
          name="description"
          content="Prix raisonnables et travail de grande qualité, contactez-nous pour un devis instantané et définitif. Super Serrurier, entreprise n°1 de Suisse Romande"
        />
      </Helmet>

      <CtaSection />
      <Features />
      <Prestations
        title="Urgence Serrurier : les prestations de Super Serrurier"
        description="Bénéficiez de nos services de dépannage de serrurier d’urgence 7j/7 et 24h/24 avec des professionnels hautement qualifiés, spécialisées dans plusieurs domaines Ajoutons à cela l’utilisation d’un matériel certifié, irréprochable et hautement sécurisé."
      />
      <OurPartners />

      <Zones
        title="Zones d’interventions"
        text="Nos serruriers couvrent toute la Suisse Romande, se déplacent dans
              toutes les communes très rapidement, et vous font bénéficier d’un
              service hautement qualifié au meilleur prix."
      />
      <BlogSection />
    </Layout>
  );
};

export default IndexPage;
