import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

export default function ({ type, title, shortText, blogUrl, cardImg }) {
  return (
    <div
      className="flex mx-auto flex-col items-center justify-center lg:w-1/3 mt-14 rounded-lg shadow-lg overflow-hidden transform duration-150 scale-100 hover:scale-105"
      style={{ width: '320px' }}
    >
      <div className="flex-shrink-0">
        <Link to={blogUrl}>
          <Img
            className="max-h-96 h-96 w-full object-cover"
            fixed={cardImg}
            alt={title}
            style={{ height: '384px' }}
          />
        </Link>
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <p className="text-sm font-medium text-indigo-600">
            <p className="cursor-default">{type}</p>
          </p>
          <Link to={blogUrl} className="block mt-2">
            <p className="text-xl font-semibold text-gray-900">{title}</p>
          </Link>
        </div>
      </div>
    </div>
  );
}
