import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import BlogCard from '../components/BlogCard';

export default function BlogSection() {
  const data = useStaticQuery(
    graphql`
      query QueryIndexBlogSections {
        posts: allGhostPost(
          limit: 3
          filter: { tags: { elemMatch: { name: { eq: "superserrurier" } } } }
        ) {
          nodes {
            slug
            title
            custom_excerpt
            feature_image_sharp {
              childImageSharp {
                fixed(width: 515) {
                  base64
                  width
                  height
                  src
                  srcSet
                }
              }
            }
          }
        }
      }
    `
  );
  return (
    <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3"></div>
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-primarycolor sm:text-4xl">
            Découvrez nos derniers articles de blog
          </h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-600 sm:mt-4">
            Nous vous proposons des articles liés au domaine de la serrurerie.
            Nous partageons avec vous des conseils, astuces et plein d’autres
            choses qui vous seront très utiles.
          </p>
        </div>
        <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {data.posts.nodes.map((post) => {
            return (
              <BlogCard
                cardImg={post.feature_image_sharp.childImageSharp.fixed}
                type="Article"
                title={post.title}
                shortText={post.custom_excerpt}
                date="Mar 16, 2020"
                blogUrl={`/blog/${post.slug}`}
                full
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
