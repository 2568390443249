import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { graphql, useStaticQuery } from 'gatsby';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function CityDropdown() {
  const data = useStaticQuery(graphql`
    query QueryDropdownCantonImages {
      fribourg: file(relativePath: { eq: "Fribourg.png" }) {
        childImageSharp {
          fixed(height: 25) {
            base64
            width
            height
            src
            srcSet
          }
        }
      }
      geneve: file(relativePath: { eq: "Geneve.png" }) {
        childImageSharp {
          fixed(height: 25) {
            base64
            width
            height
            src
            srcSet
          }
        }
      }
      neuchatel: file(relativePath: { eq: "neuchatel.png" }) {
        childImageSharp {
          fixed(height: 25) {
            base64
            width
            height
            src
            srcSet
          }
        }
      }
      valais: file(relativePath: { eq: "Valais.png" }) {
        childImageSharp {
          fixed(height: 25) {
            base64
            width
            height
            src
            srcSet
          }
        }
      }
      vaud: file(relativePath: { eq: "Vaud.png" }) {
        childImageSharp {
          fixed(height: 25) {
            base64
            width
            height
            src
            srcSet
          }
        }
      }
    }
  `);
  console.log('dropdown', data);
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-3 bg-red-500 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-red-500">
          Trouvez le serrurier le plus proche de chez vous
          <ChevronDownIcon
            className="-mr-1 ml-2 h-5 w-5 text-white"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-20 origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              <div className="py-3 border-b border-gray-200">
                <Link
                  to="/vaud"
                  className="hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-4 py-2 text-sm ml-1 flex items-center block"
                >
                  <Img fixed={data.vaud.childImageSharp.fixed} alt="Vaud" />
                  <span className="ml-4">Canton de Vaud</span>
                  {/* <ChevronDownIcon
                    className="-mr-1 ml-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  /> */}
                </Link>
                <div className="flex flex-col ml-8 space-y-2 mt-2">
                  <Link
                    to="/serrurier-lausanne"
                    className="hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-4 py-3 text-sm ml-1 flex items-center block"
                  >
                    Lausanne
                  </Link>
                  <Link
                    to="/serrurier-montreux"
                    className="hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-4 py-3 text-sm ml-1 flex items-center"
                  >
                    Montreux
                  </Link>
                </div>
              </div>
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/serrurier-geneve"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-3 text-sm ml-1 flex items-center border-b border-gray-200',
                  )}
                >
                  <Img fixed={data.geneve.childImageSharp.fixed} alt="Vaud" />
                  <span className="ml-4">Geneva</span>
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/fribourg"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-3 text-sm ml-1 flex items-center border-b border-gray-200',
                  )}
                >
                  <Img fixed={data.fribourg.childImageSharp.fixed} alt="Vaud" />
                  <span className="ml-4">Fribourg</span>
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/neuchatel"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-3 text-sm ml-1 flex items-center border-b border-gray-200',
                  )}
                >
                  <Img
                    fixed={data.neuchatel.childImageSharp.fixed}
                    alt="Neuchâtel"
                  />
                  <span className="ml-4">Neuchâtel</span>
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/valais"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'px-5 py-3 text-sm flex items-center',
                  )}
                >
                  <Img fixed={data.valais.childImageSharp.fixed} alt="Valais" />
                  <span className="ml-4">Valais</span>
                </Link>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
