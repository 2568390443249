import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

export default function Partners() {
  const data = useStaticQuery(graphql`
    query PartnersQuery {
      leman: file(relativePath: { eq: "logos/leman.jpg" }) {
        childImageSharp {
          fixed(width: 150) {
            base64
            width
            height
            src
            srcSet
          }
        }
      }
      dom: file(relativePath: { eq: "logos/dom.webp" }) {
        childImageSharp {
          fixed(width: 150) {
            base64
            width
            height
            src
            srcSet
          }
        }
      }
      keso: file(relativePath: { eq: "logos/keso.png" }) {
        childImageSharp {
          fixed(width: 150) {
            base64
            width
            height
            src
            srcSet
          }
        }
      }
      kaba: file(relativePath: { eq: "logos/kaba.png" }) {
        childImageSharp {
          fixed(width: 150) {
            base64
            width
            height
            src
            srcSet
          }
        }
      }
      glutz: file(relativePath: { eq: "logos/glutz.png" }) {
        childImageSharp {
          fixed(width: 150) {
            base64
            width
            height
            src
            srcSet
          }
        }
      }
      msl: file(relativePath: { eq: "logos/msl.png" }) {
        childImageSharp {
          fixed(width: 150) {
            base64
            width
            height
            src
            srcSet
          }
        }
      }
      honeywell: file(relativePath: { eq: "logos/honeywell.png" }) {
        childImageSharp {
          fixed(width: 150) {
            base64
            width
            height
            src
            srcSet
          }
        }
      }
      nickal: file(relativePath: { eq: "logos/nickal.png" }) {
        childImageSharp {
          fixed(width: 150) {
            base64
            width
            height
            src
            srcSet
          }
        }
      }
      certas: file(relativePath: { eq: "logos/certas.png" }) {
        childImageSharp {
          fixed(width: 150) {
            base64
            width
            height
            src
            srcSet
          }
        }
      }
    }
  `);
  const clients = [
    ['Leman', data.leman.childImageSharp.fixed, 'https://qleman.ch/'],
    ['Dom', data.dom.childImageSharp.fixed, 'https://www.dom-security.com/fr'],
    [
      'Keso',
      data.keso.childImageSharp.fixed,
      'https://www.assaabloy.com/ch/en',
    ],
    ['Kaba', data.kaba.childImageSharp.fixed, 'https://www.kaba.ch'],
    ['Glutz', data.glutz.childImageSharp.fixed, 'https://www.glutz.com/home/'],
    ['MSL', data.msl.childImageSharp.fixed, 'https://www.msl-loch.com'],
    [
      'Honeywell',
      data.honeywell.childImageSharp.fixed,
      'https://www.honeywell.com/us/en',
    ],
    ['Nickal', data.nickal.childImageSharp.fixed, 'http://www.nickal.ch/'],
    ['Certas', data.certas.childImageSharp.fixed, '#'],
  ];
  return (
    <div className="mt-24 sm:mt-32 lg:mt-20 max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto text-center">
        <h2 className="text-3xl font-extrabold text-primarycolor">
          {' '}
          Nos partenaires
        </h2>
        <p className="my-4 text-lg text-gray-600">
          Nous collaborons avec des partenaires de confiance et de qualité qui
          partagent notre engagement envers l'excellence et l'innovation.
        </p>
      </div>

      <ul className="grid grid-cols-2 gap-x-8 gap-y-12 sm:grid-cols-3 lg:grid-cols-4">
        {clients.map(([client, logo, link]) => (
          <li key={client} className="group">
            <div className="pt-12 flex justify-center items-center group-[&:nth-child(-n+2)]:-mt-px sm:group-[&:nth-child(3)]:-mt-px lg:group-[&:nth-child(4)]:-mt-px">
              <Link to={link}>
                {' '}
                <Img fixed={logo} className="zone-icon max-h-20" alt={client} />
              </Link>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
