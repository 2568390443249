import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

export default function () {
  const data = useStaticQuery(graphql`
    query FeatureImagesQuery {
      people: file(relativePath: { eq: "people-image.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      euro: file(relativePath: { eq: "euro-image.png" }) {
        childImageSharp {
          fixed(width: 490) {
            base64
            width
            height
            src
            srcSet
          }
        }
      }

      stop: file(relativePath: { eq: "stop-image.png" }) {
        childImageSharp {
          fixed(width: 500) {
            base64
            width
            height
            src
            srcSet
          }
        }
      }
      google: file(relativePath: { eq: "google-image.png" }) {
        childImageSharp {
          fixed {
            base64
            width
            height
            src
            srcSet
          }
        }
      }
    }
  `);
  return (
    <div className="ml-2 mt-8 md:py-16 bg-gray-50 overflow-hidden lg:py-24">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <svg
          className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              ></rect>
            </pattern>
          </defs>
          <rect
            width="404"
            height="784"
            fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
          ></rect>
        </svg>

        <div className="relative  lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative py-12">
            <div className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
              <h2 className=" inline text-primarycolor">Urgence Serrurier ?</h2>
              <h2 className=" inline"> Faites appel à nous !</h2>
            </div>
            <p className="mt-10 text-lg text-gray-600">
              Faites vous dépanner en cas d’urgence par les meilleurs serruriers
              de votre ville Plus de 3000 clients nous font confiance chaque
              année. Super Serrurier est l'entreprise la plus appréciée de
              Suisse Romande.
            </p>
            <a
              target={'_blank'}
              className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md bg-secondarycolor shadow-sm border-gray-200 hover:bg-secondary-light text-white mt-4 lg:w-2/3 w-full"
              href="https://www.google.com/search?q=stop+cambriolage&oq=sto&aqs=chrome.2.69i60j69i57j69i59l2j69i65j69i60j69i65l2.3738j0j7&sourceid=chrome&ie=UTF-8#lrd=0x478c2f5daf7b24df:0xbbe44c1bdd1afcf6,1,,,,"
            >
              Consultez les commentaires clients
            </a>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
              width="784"
              height="404"
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="784"
                height="404"
                fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
              ></rect>
            </svg>
            <img
              className="relative mx-auto"
              width="490"
              height="490"
              src="https://res.cloudinary.com/dnzgotmwo/image/upload/v1606606743/super/Super_serrurier_-_meilleure_serrurier-8_hyi83q.png"
              alt="google reviews"
            />
          </div>
        </div>

        <svg
          className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              ></rect>
            </pattern>
          </defs>
          <rect
            width="404"
            height="784"
            fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
          ></rect>
        </svg>

        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <h2 className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
                <span className="text-primarycolor">
                  {' '}
                  Serrurier d’urgence pas cher
                </span>{' '}
                - Attention aux arnaques
              </h2>
              <div className="mt-3 text-lg text-gray-600">
                <p>
                  La serrurerie d’urgence est un marché lucratif et non
                  régularisé, et beaucoup de serruriers sont peu qualifiés.
                  Chaque année, environ 15% de nos interventions consistent à
                  réparer des serrures et remplacer des portes. Ces dernières
                  ont été, pour la plupart, endommagées par des serruriers
                  négligents ou amateurs.
                </p>
                <p className="mt-2">
                  En conséquence, il est primordial de choisir dès le départ, un
                  partenaire fiable comme Super Serrurier.
                </p>
                <p className="mt-2">
                  Nous sommes des serruriers pas chers et très qualifiés.
                </p>
              </div>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width="784"
                height="404"
                fill="none"
                viewBox="0 0 784 404"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    ></rect>
                  </pattern>
                </defs>
                <rect
                  width="784"
                  height="404"
                  fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                ></rect>
              </svg>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Img
                  className="relative mx-auto"
                  width="435"
                  fixed={data.stop.childImageSharp.fixed}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <svg
          className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              ></rect>
            </pattern>
          </defs>
          <rect
            width="404"
            height="784"
            fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
          ></rect>
        </svg>

        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            <div className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
              <h2 className=" inline text-primarycolor">Super serrurier </h2>
              <h2 className=" inline">
                - Dépannage d’urgence à un Prix transparents
              </h2>
            </div>
            <p className="mt-3 text-lg text-gray-600">
              Nous sommes spécialisés en dépannage d’urgence, et nous nous
              déplaçons uniquement après avoir communiqué le devis et avoir reçu
              l’accord du client. Il y a ZÉRO mauvaise surprise, telle que
              l'annonce d'un nouveau prix une fois le serrurier sur place. Notre
              prix inclut :
            </p>

            <div className="mt-10 space-y-10">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg
                    className=" h-6 w-6 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                </div>
                <div className="ml-4">
                  <h4 className="text-lg leading-6 font-medium text-gray-900">
                    Les frais de déplacement
                  </h4>
                </div>
              </div>

              <div className="flex">
                <div className="flex-shrink-0">
                  <svg
                    className=" h-6 w-6 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                </div>
                <div className="ml-4">
                  <h4 className="text-lg leading-6 font-medium text-gray-900">
                    Les frais d’intervention
                  </h4>
                </div>
              </div>

              <div className="flex">
                <div className="flex-shrink-0">
                  <svg
                    className=" h-6 w-6 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                </div>
                <div className="ml-4">
                  <h4 className="text-lg leading-6 font-medium text-gray-900">
                    Le prix du matériel de rechange
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
              width="784"
              height="404"
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="784"
                height="404"
                fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
              ></rect>
            </svg>
            <Img
              className="relative mx-auto ml-8"
              width="490"
              fixed={data.euro.childImageSharp.fixed}
              alt=""
            />
          </div>
        </div>

        <svg
          className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              ></rect>
            </pattern>
          </defs>
          <rect
            width="404"
            height="784"
            fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
          ></rect>
        </svg>

        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
                <span className="text-primarycolor block">
                  Serruriers d’urgence professionnels
                </span>
                expérimentés et certifiés
              </h3>
              <p className="mt-3 text-lg text-gray-600">
                Nous avons plus de 20 ans d’expérience en tant qu’artisans
                serruriers. Nos employés sont certifiés et expérimentés dans le
                domaine. Nous vous garantissons:
              </p>

              <div className="mt-10 space-y-10">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg
                      className=" h-6 w-6 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">
                      Un travail de qualité inégalable
                    </h4>
                    <p className="mt-2 text-base text-gray-600">
                      Chez Super Serrurier, la mise en sécurité de vos
                      installations est faite par un expert afin de satisfaire
                      vos exigences. Le matériel utilisé est certifié, de grande
                      qualité et de haute sécurité.
                    </p>
                  </div>
                </div>

                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg
                      className=" h-6 w-6 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">
                      Une intervention rapide
                    </h4>
                    <p className="mt-2 text-base text-gray-600">
                      Notre réseau de serruriers étant très développé. Nous
                      sommes présents à travers plusieurs villes de Suisse
                      Romande. Nous pouvons intervenir en cas d’urgence en moins
                      de 20 minutes.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width="784"
                height="404"
                fill="none"
                viewBox="0 0 784 404"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    ></rect>
                  </pattern>
                </defs>
                <rect
                  width="784"
                  height="404"
                  fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                ></rect>
              </svg>
              <Img
                className="relative mx-auto w-5/6"
                fluid={data.people.childImageSharp.fluid}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
