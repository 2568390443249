import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

export default function Zones({ text, title }) {
  const data = useStaticQuery(graphql`
    query MyQuery {
      fribourg: file(relativePath: { eq: "Fribourg.png" }) {
        childImageSharp {
          fixed(height: 65) {
            base64
            width
            height
            src
            srcSet
          }
        }
      }
      geneve: file(relativePath: { eq: "Geneve.png" }) {
        childImageSharp {
          fixed(height: 65) {
            base64
            width
            height
            src
            srcSet
          }
        }
      }
      neuchatel: file(relativePath: { eq: "neuchatel.png" }) {
        childImageSharp {
          fixed(height: 65) {
            base64
            width
            height
            src
            srcSet
          }
        }
      }
      valais: file(relativePath: { eq: "Valais.png" }) {
        childImageSharp {
          fixed(height: 65) {
            base64
            width
            height
            src
            srcSet
          }
        }
      }
      vaud: file(relativePath: { eq: "Vaud.png" }) {
        childImageSharp {
          fixed(height: 65) {
            base64
            width
            height
            src
            srcSet
          }
        }
      }
    }
  `);
  return (
    <div className="ml-2 bg-gray-50">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div>
            <h3 className="text-2xl font-extrabold text-primarycolor sm:text-4xl">
              {title}
            </h3>
            <p
              className="mt-3 max-w-3xl text-lg text-gray-600"
              dangerouslySetInnerHTML={{ __html: text }}
            />
            <div className="hidden sm:block">
              <div className="mt-8 sm:flex">
                <div className="rounded-md shadow">
                  <a
                    href="tel:0791304768"
                    className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-secondarycolor hover:bg-secondarycolor-light lg:hidden"
                  >
                    Appelez-nous
                  </a>
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3 lg:ml-0">
                  <a
                    href="/contact"
                    className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-secondarycolor shadow-sm border-gray-200 bg-white hover:bg-gray-200"
                  >
                    Contact
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
            <div className="col-span-1 flex lg:justify-center py-8 lg:px-8">
              <Link
                to="/valais"
                className="zone-area duration-150 flex items-center text-gray-600 hover:text-primarycolor"
              >
                <Image
                  fixed={data.valais.childImageSharp.fixed}
                  className="zone-icon max-h-20"
                  alt="Valais"
                />
                <h4 className="text-1xl font-extrabold ml-2">Valais</h4>
              </Link>
            </div>
            <div className="col-span-1 flex lg:justify-center py-8 lg:px-8">
              <Link
                to="/serrurier-geneve"
                className="zone-area duration-150 flex items-center lg:ml-8 text-gray-600 hover:text-primarycolor"
              >
                <Image
                  className="zone-icon max-h-20"
                  fixed={data.geneve.childImageSharp.fixed}
                  alt="Genève"
                />
                <h4 className="text-1xl font-extrabold ml-2">Genève</h4>
              </Link>
            </div>
            <div className="col-span-1 flex lg:justify-center py-8 lg:px-8">
              <Link
                to="/neuchatel"
                className="zone-area duration-150 flex items-center lg:ml-8 text-gray-600 hover:text-primarycolor"
              >
                <Image
                  className="zone-icon max-h-20"
                  fixed={data.neuchatel.childImageSharp.fixed}
                  alt="Neuchatel"
                />
                <h4 className="text-1xl font-extrabold ml-2">Neuchatel</h4>
              </Link>
            </div>
            <div className="col-span-1 flex lg:justify-center py-8 lg:px-8 lg:ml-4 text-gray-600 hover:text-primarycolor">
              <Link
                to="/vaud"
                className="zone-area duration-150 flex items-center"
              >
                <Image
                  className="zone-icon max-h-20"
                  fixed={data.vaud.childImageSharp.fixed}
                  alt="Vaud"
                />
                <h4 className="text-1xl font-extrabold ml-2">Vaud</h4>
              </Link>
            </div>
            <div className="col-span-1 flex lg:justify-center py-8 lg:px-14 text-gray-600 hover:text-primarycolor">
              <Link
                to="/fribourg"
                className="zone-area duration-150 flex justify-between items-center"
              >
                <FribourgImage
                  className="zone-icon max-h-20"
                  fixed={data.fribourg.childImageSharp.fixed}
                  alt="Fribourg"
                />
                <h4 className="text-1xl font-extrabold ml-2">Fribourg</h4>
              </Link>
            </div>
          </div>

          <div className="block sm:hidden">
            <div className="mt-8 sm:flex">
              <div className="rounded-md shadow">
                <a
                  href="tel:0791304768"
                  className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-secondarycolor hover:bg-secondarycolor-light lg:hidden"
                >
                  Appelez-nous
                </a>
              </div>
              <div className="mt-3 sm:mt-0 sm:ml-3 lg:ml-0">
                <a
                  href="/contact"
                  className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-secondarycolor shadow-sm border-gray-200 bg-white hover:bg-gray-200"
                >
                  Contact
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const Image = styled(Img)`
  filter: grayscale(100%);

  &:hover {
    filter: none;
  }
`;

const FribourgImage = styled(Img)`
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`;
