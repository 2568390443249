import React from 'react';
import { Link } from 'gatsby';
import { SERVICES } from '../data/services';

export default function Prestations({ title, description }) {
  return (
    <div id="prestations" className="relative ml-2 bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-primarycolor">{title}</h2>
          <p className="my-4 text-lg text-gray-600">{description}</p>
        </div>
        <div className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
          {SERVICES.map((service) => {
            return (
              <Prestation
                title={service.name}
                key={service.name}
                link={service.link}
                description={service.description}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

const Prestation = ({ title, description, link }) => (
  <div className="flex">
    <svg
      className="flex-shrink-0 h-6 w-6 text-green-500"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 13l4 4L19 7"
      ></path>
    </svg>
    <div className="ml-3">
      <Link to={link}>
        {' '}
        <h4 className="text-lg leading-6 font-medium cursor-pointer text-gray-900">
          {title}
        </h4>
      </Link>
      <p className="mt-2 text-base text-gray-600">{description}</p>
    </div>
  </div>
);
